import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import { Tabs, Table, Modal, Input, Timeline, Pagination, Tag } from "antd";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Select,
  FormControlLabel,
  MenuItem,
  Button,
  Collapse,
  Checkbox,
  ButtonGroup,
  Box,
  Link,
  InputAdornment,
  IconButton,
  Chip,
  Avatar,
  Tooltip,
} from "@mui/material";
import { SmileOutlined } from "@ant-design/icons";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Scheduling from "./Scheduling";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoComponent from "components/Info";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import VerifiedIcon from "@mui/icons-material/Verified";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import {
  bp_get_batch_details,
  bp_get_batch_report_view,
  get_batch_details_by_process_id,
  get_schedule_batch_details,
  get_running_batch_details,
  get_completed_batch_details,
  get_employee,
  bp_get_batch_process_by_schedule,
  bp_get_depart_wise_employee,
  bp_update_starttime_for_batch_number,
  get_batch_copy_details,
  get_batch_changes_details,
  bp_update_employee_for_batch_number,
  get_api_function,
} from "utils/api";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { toast } from "react-toastify";
import OperationsSkeleton from "../RecipeOperationsDetails";
import DAGFlow from "pages/dashboard/DAGFlow";
import DAGFlowCopy from "pages/dashboard/DAGFlow copy";
import { DatePicker } from "antd";
import TableInModal from "components/TableInModal";
function ScheduledBatches() {
  const [user_access_data, set_user_access_data] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [dept_wise_emp, set_dept_wise_emp] = useState([]);
  const [show_add_schedule, set_show_add_schedule] = useState(false);
  const [batch_data, set_batch_data] = useState([]);
  const [searchvalue, setSearchvalue] = useState("");
  const [refresh, set_refresh] = useState(false);
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [processOperations, set_processOperations] = useState([]);
  const [tab_value, set_tab_value] = useState("1");
  const [showSummary, set_showSummary] = useState(false);
  const [showSummaryIndex, set_showSummaryIndex] = useState();
  const [assignStatus, setAssignStatus] = useState(false);
  const [selected_recipe, set_selected_recipe] = useState("");
  const [curruntExpandedkey, setCurruntExpandedkey] = useState("");
  const [reportView, setReportView] = useState(false);
  const [selectedProcessId, set_selectedProcessId] = useState("");
  const [selectedProcessName, set_selectedProcessName] = useState("");
  const [selectedButton, setSelectedButton] = useState("test");
  const [modifiedData, setModifiedData] = useState([]);
  const [loading, set_loading] = useState(false);
  const [row_expand, set_row_expand] = useState([]);
  //Pagination
  const [total, setTotal] = useState("");
  const [page_number, set_page_number] = useState(1);
  const [isVisible, set_isVisible] = useState(false);
  const [range_value, set_range_value] = useState([]);
  const [modified_details, set_modified_details] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const { RangePicker } = DatePicker;
  const handleClick = (button) => {
    setSelectedButton(button);
    set_refresh(!refresh);
  };

  const ingredientStringConcat = (data, header) => {
    return data?.map((ingredient, index) => {
      let ingredientString = `${ingredient?.material_name?.split("(")[0]} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist
        ingredientString += "";
        // ingredient.parameter_name && `(${ingredient.parameter_name}), `;"
      } else {
        ingredientString += `: ${ingredient.quantity}(${ingredient?.material_unit})`;
        if (ingredient.parameter_name !== "") {
          ingredientString += `${ingredient.parameter_name}`;
        } else {
          ingredientString += `-`;
        }
      }
      ingredientString += index === data?.length - 1 ? "" : ",";
      return ingredientString;
    });
  };

  const buttonStyles = (isSelected) => ({
    width: "100px",
    backgroundColor: isSelected ? "#1976d2" : "inherit", // Use primary.main color or change it to match your theme
    color: isSelected ? "#ffffff" : "inherit", // Use primary.contrastText color or change it to match your theme
    border: "1px solid #1976d2", // Optional: to have a consistent border color
    "&:hover": {
      backgroundColor: isSelected ? "#115293" : "inherit", // Use primary.dark color or change it to match your theme
    },
  });

  const get_batch_details_by_process = async (process_id, batch_number) => {
    try {
      await get_batch_details_by_process_id(res_get_details, {
        process_id: process_id,
        batch_number: batch_number,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const checkSchedule = (scheduleList) => {
    return scheduleList?.every(
      (item) => item.schedule_date && item.employee_id?.length > 0
    );
  }; // to check all operations are scheduled or not

  const res_get_details = (res) => {
    if (res?.data?.status === "success") {
      if (res?.data?.data && res?.data?.data?.length > 0) {
        set_processOperations(res?.data?.data[0]?.operations);
        const reqList = res?.data?.data[0]?.operations?.map((item, index) => {
          return {
            status: false,
            value: "",
            emp_status: "", // Update the key to the index converted to a string
          };
        });
        setScheduleTimeStatus(reqList);
      } else {
        setScheduleTimeStatus([]);
      }
    }
  };

  const formatDate = (date) => {
    const dateObject = new Date(date);
    const formattedDateTime = `${dateObject.getFullYear()}-${(
      dateObject.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateObject
      .getDate()
      .toString()
      .padStart(2, "0")} ${dateObject
      .getHours()
      .toString()
      .padStart(2, "0")}:${dateObject
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${dateObject
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return formattedDateTime;
  };

  const ingredientStrings = (data, header) => {
    return data?.map((ingredient) => {
      let ingredientString = `${ingredient.ingredient_name} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist
        ingredientString = `${ingredientString},`;
      } else {
        ingredientString += `${ingredient.ingredient_qty + " "} ${
          ingredient.conditinal_para
        }, `;
        if (
          ingredient.parameter_name !== "" &&
          ingredient.min_value >= 0 &&
          ingredient.max_value > 0
        ) {
          ingredientString += ` (${ingredient.parameter_name} with min ${ingredient.min_value}${ingredient.parameter_name_unit} and max ${ingredient.max_value}${ingredient.parameter_name_unit})`;
        }
      }
      return ingredientString;
    });
  };

  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  const [current_batch, set_current_batch] = useState("");
  const [current_expand, set_current_expand] = useState("");
  const [current_row_expand, set_current_row_expand] = useState([]);
  const [scheduleTimeStatus, setScheduleTimeStatus] = useState([]);
  const [currentExpandedbatchId, setCurrentExpandedbatchId] = useState();
  const [bulk_assign_list, set_bulk_assign_list] = useState([]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      get_batch_process_by_schedule(record.batch_number, record.batch_id);
      setCurrentExpandedbatchId(record.batch_id); // set the expanded row batch id
      set_current_batch(record.batch_number);
      set_current_expand(record?.key);
      get_api_function(
        `/get_employees_for_batch?batch_number=${record.batch_number}`,
        (res) => {
          if (res.data.status === "success") {
            set_bulk_assign_list(res.data.data);
          }
        }
      );
    } else {
      set_current_expand("");
    }
  };

  const handleExpandRow = (expanded, record) => {
    if (expanded) {
      set_row_expand(record?.key);
    } else {
      set_row_expand("");
    }
  };

  const handleOperationExpand = (expanded, record) => {
    if (expanded) {
      set_current_row_expand([record?.key]);
      const reqList = record?.operations?.map((item, index) => {
        return {
          status: false,
          value: "",
          emp_status: "", // Update the key to the index converted to a string
        };
      });
      setScheduleTimeStatus(reqList);
    } else {
      set_current_row_expand([]);
      setScheduleTimeStatus([]);
    }
  };
  const [dag_nodes, set_dag_nodes] = useState([]);

  // const get_operations_by_process_and_batch = (record, process, index) => {
  //   get_api_function(
  //     `/get_batch_process__details_by_schedule?batch_number=${record?.batch_number}&process_id=${process?.process_id}`,
  //     (res) => {
  //       set_selected_recipe(record?.recipe_name);
  //       setCurruntExpandedkey(index);
  //       handleOperationExpand(true, process);
  //       set_selectedProcessId(process?.process_id);
  //       set_current_batch(record?.batch_number);
  //       set_processOperations(res.data.data);
  //       set_showSummary(true);
  //     }
  //   );
  // };
  const handleCancel = () => {
    set_isVisible(false);
  };
  const processColumns = [
    {
      title: "Process Name",
      dataIndex: "process_name",
      key: "Operation",
      align: "center",
    },
  ];
  const expandedRowRenderCols = (record) => {
    const updateData = record?.operations?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });

    const modiedDataColums = [
      {
        title: "Operation Number",
        dataIndex: "operation_number",
        key: "Operation",
        align: "center",
      },
      {
        title: "Operation",
        dataIndex: "operation_type",
        key: "operation_type",
        align: "center",
        render: (data, record, index) => {
          const ingred = ingredientStringConcat(
            record.ingredients,
            "operation"
          );

          return (
            <div style={{ textAlign: "left" }}>
              <p>
                <strong> Operation Type: </strong>
                {`${data}, `} <br />
                {record.instruction && (
                  <>
                    <strong>Instruction:</strong>
                    <span>{`${record.instruction}`}</span>
                    <br />
                  </>
                )}
                {record?.dependencie && (
                  <>
                    <strong> Depending on Operations: </strong>
                    <span>{`${record?.dependencie}`}</span>
                  </>
                )}
              </p>
              <p>
                {ingred && ingred?.length > 0 && (
                  <>
                    <strong> Ingredients: </strong>
                    {ingred.join("\n")}
                  </>
                )}
              </p>
            </div>
          );
        },
      },
      {
        title: "Equipment",
        dataIndex: "machine_name",
        align: "center",
        key: "machine_name",
      },
      {
        title: "Duration",
        dataIndex: "max_duration",
        key: "max_duration",
        align: "center",
      },
      {
        title: "Details / Remarks",
        dataIndex: "products",
        key: "process_products",
        width: "25%",
        align: "center",
        render: (data, record, index) => {
          console.log(record?.ingredients);

          const ingred = ingredientStringConcat(record?.ingredients, "");
          let mul = "X Batch Size";
          let perc = "% of Batch Size";
          return (
            <div style={{ textAlign: "left" }}>
              {ingred?.length > 0 && (
                <>
                  <strong> Ingredients: </strong>
                  {/* {ingred?.map((data, index) => (
                        <div key={index}>{data}</div>
                      ))} */}
                  <div className="mb-4">
                    {ingred?.map((data, index) => {
                      return (
                        <>
                          <div key={index}>
                            <strong>{`${index + 1}.  `}</strong>
                            {data?.split("#")?.length > 1
                              ? `${data?.split("#")[0]}-- [${
                                  data?.split("#")[1]
                                }] `
                              : data?.replace("-", "")}
                            {/* {record?.ingredients[index]?.is_percentage
                              ? "" + perc
                              : record?.ingredients[index]?.unit + mul} */}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <Table
        dataSource={updateData}
        columns={modiedDataColums}
        pagination={false}
        showHeader={true}
        expandable={{
          expandedRowRender: expandedRowRenderData,
          onExpand: handleExpandRow,
          // onExpandedRowsChange: (expanded) => set_row_expand(expanded),
          expandedRowKeys: [row_expand],
          // expandedRowClassName: () => "col2",
        }}
      />
    );
  };

  const deviation_color = (per) => {
    let color;
    let bg_color;

    switch (true) {
      case per === "":
        break;
      case per >= 0 && per <= 5:
        color = "green";
        bg_color = "#CCFFCC ";
        break;
      case (per >= -10 && per < 0) || (per > 5 && per <= 50):
        color = "orange";
        bg_color = "#FFF59E";
        break;
      case per < -10 || per > 50:
        color = "red";
        bg_color = "#FFCCCC";
        break;
      default:
        color = "black";
    }
    return { color: color, bg_color: bg_color };
  };

  const expandedRowRenderData = (record) => {
    return (
      <Collapse accordion>
        {record?.products?.length > 0 && (
          <>
            <Panel header="Product" key="1">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Product Name",
                    dataIndex: "product_name",
                    key: "product_name",
                  },
                  {
                    title: "Process Thero Quantity",
                    dataIndex: "process_thero_quantity",
                    key: "operation_number",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Min Value",
                    dataIndex: "process_expec_min_value",
                    key: "process_expec_min_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Max Value",
                    dataIndex: "process_expec_max_value",
                    key: "process_expec_max_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                ]}
                dataSource={record?.products}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          </>
        )}

        {record.paramaters && record.paramaters?.length > 0 && (
          <>
            <Panel header="Process Parameters" key="2">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Parameter",
                    dataIndex: "parameter_name",
                    rowScope: "row",
                  },
                  {
                    title: "Readings Per Interval (HH:MM:SS)",
                    dataIndex: "parameter_max_value",
                    key: "parameter_max_value",
                    render: (data, record, index) => {
                      return <p>{`${data}`}</p>;
                    },
                  },
                  {
                    title: "Min",
                    dataIndex: "min_value",
                    key: "min_value",
                    align: "center",
                  },
                  {
                    title: "Max",
                    dataIndex: "max_value",
                    key: "max_value",
                    align: "center",
                  },
                ]}
                dataSource={record.paramaters}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          </>
        )}
        {record.operation_type === "Sampling" &&
          record.sampling &&
          record.sampling.length > 0 && (
            <Panel header="Sampling" key="3">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Output",
                    align: "center",
                    dataIndex: "material_inventory_name",
                    key: "material_inventory_name",
                  },
                  {
                    title: "Quantity",
                    align: "center",
                    dataIndex: "quantity",
                    key: "quantity",
                    render: (data, record, index) => {
                      return (
                        <p>{`${data} (${record.material_inventory_unit})`}</p>
                      );
                    },
                  },
                ]}
                dataSource={record.sampling}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          )}

        {record?.machine_list && record?.machine_list?.length > 0 && (
          <Panel header="Machines" key="4">
            <Table
              size="small"
              pagination={false}
              columns={[
                {
                  title: "Machine Name",
                  dataIndex: "machine_name",
                  key: "machine_name",
                },
              ]}
              dataSource={record?.machine_list}
              bordered
              style={{ width: "100%" }}
            />
          </Panel>
        )}
      </Collapse>
    );
  };

  const expandedRowRender = (record) => {
    const updateData = batchProcessBySchedule?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return (
      <Timeline
        items={updateData?.map((process, index) => ({
          color: process?.process_status ? "green" : "#FAAD14",
          children: (
            <>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>
                  <b>{"Process: "}</b>
                  {`${process?.process_number}`}
                </Typography>

                {/* // !process?.operations?.length > 0 ? (
                  //   <Tag color="red">{"Pending"}</Tag>
                  // ) : */}

                <>
                  {process?.process_status ? (
                    <Tooltip title="All Operations Scheduled">
                      <VerifiedIcon
                        color="success"
                        style={{ fontSize: "20px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Schedule Pending">
                      <AccessTimeFilledIcon
                        color="warning"
                        style={{ fontSize: "20px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                  {showSummaryIndex !== index ? (
                    <Tooltip title="View Operations">
                      <IconButton
                        onClick={() => {
                          set_selected_recipe(record?.recipe_name);
                          setCurruntExpandedkey(index);
                          handleOperationExpand(true, process);
                          set_selectedProcessId(process?.process_id);
                          set_selectedProcessName(process?.process_name);
                          set_current_batch(record?.batch_number);
                          set_showSummary(true);
                          set_showSummaryIndex(index);
                        }}
                      >
                        <VisibilityIcon
                          color="primary"
                          style={{ fontSize: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Close">
                      <IconButton
                        onClick={() => {
                          set_showSummary(false);
                        }}
                      >
                        <CloseIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              </Stack>
            </>
          ),
        }))}
      />
    );
  };
  // collapse for operations
  const expandedRowRenderForOperations = (record) => {
    const updateData = record?.operations?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });

    const operationsColumns = [
      {
        title: "Operation Number",
        dataIndex: "operation_number",
        key: "Operation",
        align: "center",
      },
      {
        title: "Operation",
        dataIndex: "operation_type",
        width: "18%",
        key: "operation_type",
        align: "center",
        render: (data, record, index) => {
          const ingred = ingredientStrings(record.ingredients, "operation");

          return (
            <div style={{ textAlign: "left" }}>
              <p>
                <strong> Operation Type: </strong>
                {`${data}, `} <br />
                <strong> Instruction: </strong> {`${record.instruction}`}
              </p>
              <p>
                {ingred && ingred.length > 0 && (
                  <>
                    <strong> Ingredients: </strong>
                    {ingred.join("\n")}
                  </>
                )}
              </p>
            </div>
          );
        },
      },
      // {
      //   title: "Batch Id",
      //   dataIndex: "batch_id",
      //   key: "batch_id",
      //   align: "center",
      // },
      {
        title: "Equipment",
        dataIndex: "machine_name",
        align: "center",
        width: "8%",
        key: "machine_name",
      },
      {
        title: "Duration",
        dataIndex: "max_duration",
        key: "max_duration",
        width: "8%",
        align: "center",
      },
      {
        title: "Details / Remarks",
        dataIndex: "products",
        key: "process_products",
        width: "25%",
        align: "center",
        render: (data, record, index) => {
          const columns = [
            {
              title: "Product Name",
              dataIndex: "product_name",
              key: "product_name",
            },
            {
              title: "Process Thero Quantity",
              dataIndex: "process_thero_quantity",
              key: "operation_number",
              render: (data, record, index) => {
                return <p>{`${data} (${record.product_name_unit})`}</p>;
              },
            },
            {
              title: "Expected Min Value",
              dataIndex: "process_expec_min_value",
              key: "process_expec_min_value",
              render: (data, record, index) => {
                return <p>{`${data} (${record.product_name_unit})`}</p>;
              },
            },
            {
              title: "Expected Max Value",
              dataIndex: "process_expec_max_value",
              key: "process_expec_max_value",
              render: (data, record, index) => {
                return <p>{`${data} (${record.product_name_unit})`}</p>;
              },
            },
          ];

          const machineCols = [
            // {
            //   title: "Machine Id",
            //   dataIndex: "machine_id",
            //   key: "machine_name",
            // },
            {
              title: "Machine Name",
              dataIndex: "machine_name",
              key: "machine_name",
            },
          ];

          const pr_columns = [
            {
              title: "Parameter",
              dataIndex: "parameter_name",
              rowScope: "row",
            },
            {
              title: "Readings Per Interval (HH:MM:SS)",
              dataIndex: "parameter_max_value",
              key: "parameter_max_value",
              render: (data, record, index) => {
                return <p>{`${data}`}</p>;
              },
            },
            {
              title: "Min",
              dataIndex: "min_value",
              key: "min_value",
              align: "center",
            },
            {
              title: "Max",
              dataIndex: "max_value",
              key: "max_value",
              align: "center",
            },
          ];

          const samplingCols = [
            {
              title: "Output",
              align: "center",
              dataIndex: "material_inventory_name",
              key: "material_inventory_name",
            },
            {
              title: "Quantity",
              align: "center",
              dataIndex: "quantity",
              key: "quantity",
              render: (data, record, index) => {
                return <p>{`${data} (${record.material_inventory_unit})`}</p>;
              },
            },
          ];

          const ingred = ingredientStrings(record.ingredients, "");
          return (
            <div style={{ textAlign: "left" }}>
              {ingred?.length > 0 && (
                <>
                  <strong> Ingredients: </strong>
                  <div className="mb-4">{ingred.join("\n")}</div>
                </>
              )}

              {data && data.length > 0 && (
                <>
                  <div className="mb-3">
                    <strong> Product: </strong>
                  </div>
                  <div style={{ margin: 10, marginLeft: -35 }}>
                    <Table
                      size="small"
                      pagination={false}
                      columns={columns}
                      dataSource={data}
                      bordered
                      style={{ width: "100%" }}
                    />
                  </div>
                  <br />
                </>
              )}
              {record.paramaters && record.paramaters?.length > 0 && (
                <>
                  <div className="mb-3">
                    <strong> Process Parameters: </strong>
                  </div>
                  <div style={{ margin: 10, marginLeft: -35 }}>
                    <Table
                      size="small"
                      pagination={false}
                      columns={pr_columns}
                      dataSource={record.paramaters}
                      bordered
                      style={{ width: "100%" }}
                    />
                  </div>
                  <br />
                </>
              )}

              {record.operation_type === "Sampling" &&
                record.sampling &&
                record.sampling.length > 0 && (
                  <>
                    <div className="mb-3">
                      <strong> Sampling: </strong>
                    </div>
                    <div style={{ margin: 10, marginLeft: -35 }}>
                      <Table
                        size="small"
                        pagination={false}
                        columns={samplingCols}
                        dataSource={record.sampling}
                        bordered
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                )}

              {record.operation_number === 1 &&
                record.machine_list &&
                record.machine_list?.length > 0 && (
                  <>
                    <div className="mb-3">
                      <strong> Machines: </strong>
                    </div>
                    <div style={{ margin: 10, marginLeft: -35 }}>
                      <Table
                        size="small"
                        pagination={false}
                        columns={machineCols}
                        dataSource={record.machine_list}
                        bordered
                        style={{ width: "100%" }}
                      />
                    </div>
                  </>
                )}
            </div>
          );
        },
      },
      {
        title: "Schedule Time",
        dataIndex: "schedule_date",
        key: "schedule_time",
        width: "10%",
        align: "center",
        render: (data, record, index) => {
          if (scheduleTimeStatus[index]?.status) {
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <OutlinedInput
                    id="des"
                    type="datetime-local"
                    size="small"
                    name="des"
                    fullWidth
                    inputProps={{
                      min:
                        index === 0
                          ? formatDate(data)
                          : formatDate(updateData[index - 1]?.schedule_date), // for schedule nma efor operation1 should be greater than actual value and 2nd index should be greater than privious
                    }}
                    disabled={!scheduleTimeStatus[index]?.status}
                    //   onClick={() => setIsScheduledDateEdit(true)} // Set isEditing to true on click
                    onChange={(e) => {
                      const updatedScheduleTimeStatus = [...scheduleTimeStatus];
                      updatedScheduleTimeStatus[index].value = e.target.value;
                      setScheduleTimeStatus(updatedScheduleTimeStatus);
                    }}
                  />
                  <Tooltip title="Close">
                    <IconButton
                      onClick={() => {
                        const updatedScheduleTimeStatus = [
                          ...scheduleTimeStatus,
                        ];
                        updatedScheduleTimeStatus[index].status = false;
                        updatedScheduleTimeStatus[index].value = "";
                        setScheduleTimeStatus(updatedScheduleTimeStatus);
                      }}
                    >
                      <CancelIcon color="error" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Save">
                    <IconButton
                      disabled={!scheduleTimeStatus[index].value}
                      onClick={() => {
                        const updatedScheduleTimeStatus = [
                          ...scheduleTimeStatus,
                        ];
                        updatedScheduleTimeStatus[index].status = false;
                        assign_employee({
                          batch_schedule_time: scheduleTimeStatus[index].value,
                          batch_id: record?.batch_id,
                        });

                        setScheduleTimeStatus(updatedScheduleTimeStatus);
                      }}
                    >
                      <CheckCircleIcon color="success" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </>
            );
          } else {
            const dateObject = new Date(data);
            const formattedDateTime = `${dateObject.getFullYear()}-${(
              dateObject.getMonth() + 1
            )
              .toString()
              .padStart(2, "0")}-${dateObject
              .getDate()
              .toString()
              .padStart(2, "0")} ${dateObject
              .getHours()
              .toString()
              .padStart(2, "0")}:${dateObject
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${dateObject
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;
            return (
              <>
                <Stack direction="row" spacing={1}>
                  <span>{formattedDateTime}</span>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => {
                        const updatedScheduleTimeStatus = [
                          ...scheduleTimeStatus,
                        ];
                        updatedScheduleTimeStatus[index].status = true;
                        setScheduleTimeStatus(updatedScheduleTimeStatus);
                      }}
                    >
                      <BorderColorRoundedIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </>
            );
          }
        },
      },
      {
        title: "Assign",
        dataIndex: "",
        key: "assign",
        width: "18%",
        align: "center",
        render: (data, record, index) => {
          return record?.select_emp_list?.length <= 0 ||
            (scheduleTimeStatus?.length > 0 &&
              scheduleTimeStatus[index]?.emp_status) ? (
            <Stack direction="row" spacing={1}>
              <Select
                multiple
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedEmp[index] || []}
                style={{ width: 150 }}
                required
                onChange={(e) => {
                  let temp = [...selectedEmp];
                  temp[index] = e.target.value;
                  setSelectedEmp(temp);
                }}
              >
                {record?.emp_list?.map((mdata) => (
                  <MenuItem value={mdata.employee_id}>
                    {mdata.employee_name}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="Close">
                <IconButton
                  onClick={() => {
                    const updatedScheduleTimeStatus = [...scheduleTimeStatus];
                    updatedScheduleTimeStatus[index].emp_status = false;
                    setScheduleTimeStatus(updatedScheduleTimeStatus);
                  }}
                >
                  <CancelIcon color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Assign">
                <IconButton
                  disabled={!selectedEmp[index] || false}
                  onClick={() => {
                    assign_employee({
                      employee_id: selectedEmp[index],
                      batch_id: record?.batch_id,
                    });
                    const updatedScheduleTimeStatus = [...scheduleTimeStatus];
                    updatedScheduleTimeStatus[index].emp_status = false;
                    setScheduleTimeStatus(updatedScheduleTimeStatus);
                  }}
                >
                  <CheckCircleIcon color="success" />
                </IconButton>
              </Tooltip>
            </Stack>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              {record?.select_emp_list?.map((emp) => (
                <Chip
                  sx={{
                    m: 1,
                  }}
                  label={emp}
                />
              ))}
              <Tooltip title="Reassign">
                <IconButton
                  onClick={() => {
                    const updatedScheduleTimeStatus = [...scheduleTimeStatus];
                    updatedScheduleTimeStatus[index].emp_status = true;
                    setScheduleTimeStatus(updatedScheduleTimeStatus);
                  }}
                >
                  <BorderColorRoundedIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ];
    return (
      <Table
        columns={operationsColumns}
        dataSource={updateData}
        pagination={false}
        bordered
      />
    );
  };

  const get_view_function = () => {
    return user_access_data?.find((item) => item.module_name === "Result")
      ?.read_access
      ? [
          {
            title: "Summary Result",
            dataIndex: "summaryDetails",
            key: "id",
            align: "center",
            render: (data, record, index) => {
              return (
                <Button
                  size="medium"
                  onClick={() => {
                    set_current_batch(record?.batch_number);
                    bp_get_batch_report_view(
                      (res) => {
                        setReportData(res?.data?.data || []);
                      },
                      { batch_number: record?.batch_number }
                    );
                    set_selected_recipe(record?.recipe_name);
                    setReportView(true);
                  }}
                >
                  <VisibilityIcon color="primary" />
                </Button>
              );
            },
          },
        ]
      : [];
  };

  const [batchProcessBySchedule, setBatchProcessBySchedule] = useState([]);

  const get_batch_process_by_schedule = async (batchNo, batchId) => {
    try {
      await bp_get_batch_process_by_schedule(
        batchNo.toString(),
        batchId,
        success_get_batch_process_by_schedule
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_get_batch_process_by_schedule = (res) => {
    setBatchProcessBySchedule(res?.data?.data);
    // if (curruntExpandedkey !== "") {
    //   set_processOperations(res?.data?.data[curruntExpandedkey]?.operations);
    // }
  };

  const get_employee_list = async () => {
    try {
      await get_employee(successGetEmployee);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const successGetEmployee = (res) => {
    setEmployeeList(res?.data?.data);
  };

  const assign_employee = async (assignReq) => {
    try {
      await bp_update_employee_for_batch_number(
        assignReq,
        success_assign_employee
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_assign_employee = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      handleExpand(true, {
        batch_number: current_batch,
        batch_id: currentExpandedbatchId,
        key: current_expand,
      });
      // handleExpand();
    }
  };

  const get_view_function1 = () => {
    return user_access_data?.find((item) => item.module_name === "Result")
      ?.read_access
      ? [
          {
            title: "Summary Result",
            dataIndex: "summaryDetails",
            key: "id",
            align: "center",
            render: (data, record, index) => {
              return (
                <Button
                  size="medium"
                  type="reset"
                  onClick={() => {
                    set_current_batch(record?.batch_number);
                    // handleOperationExpand(true, record);
                    bp_get_batch_report_view(
                      (res) => {
                        setReportData(res?.data?.data || []);
                      },
                      {
                        batch_number: record?.batch_number,
                      }
                    );
                    set_selected_recipe(record?.recipe_name);
                    setReportView(true);
                  }}
                >
                  <VisibilityIcon
                    // color={record?.status ? "success" : "error"}
                    color="primary"
                  />
                </Button>
              );
              // ) : (
              //   <Tooltip title="Operation Not completed">
              //     <VisibilityIcon color="warning" />
              //   </Tooltip>
              // );
            },
          },
        ]
      : [];
  };

  const modified_Batch_columns = [
    {
      title: "Recipe",
      dataIndex: "recipe_name",
      key: "recipe_name",
      align: "center",
    },
    {
      title: "Batch Number",
      dataIndex: "batch_number",
      key: "recipe_name",
      align: "center",
    },
    {
      title: "View Details",
      dataIndex: "dt",
      key: "recipe_name",
      align: "center",
      render: (data, record, index) => {
        return (
          <IconButton
            onClick={() => {
              get_batch_changesDetails(record?.recipe_id);
              set_isVisible(true);
            }}
          >
            <VisibilityIcon color="success" />
          </IconButton>
        );
      },
    },
  ];

  const completed_Batch_columns = [
    {
      title: "Recipe",
      dataIndex: "recipe_name",
      key: "recipe_name",
      align: "center",
    },
    {
      title: "Batch",
      dataIndex: "batch_number",
      key: "batch_number",
      align: "center",
    },
    {
      title: "Batch Size",
      dataIndex: "batch_size",
      key: "batch_size",
      align: "center",
    },
    {
      title: "Machine",
      dataIndex: "machine",
      key: "machine",
      align: "center",
    },
    {
      title: "Scheduled Time",
      dataIndex: "batch_schedule_time",
      key: "batch_schedule_time",
      width: "11%",
      align: "center",
      render: (data) => {
        // return formatDate(data);
        return data?.slice(0, 16);
      },
    },
    {
      title: "Start Time",
      dataIndex: "batch_start_time",
      key: "batch_start_time",
      width: "11%",
      align: "center",
      render: (batch_start_time) => batch_start_time?.slice(0, 16),
    },
    {
      title: "Completion Time",
      dataIndex: "batch_end_time",
      key: "batch_end_time",
      width: "11%",
      align: "center",
      render: (batch_end_time) => batch_end_time?.slice(0, 16),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      width: "7%",
      align: "center",
    },
    {
      title: "Approval Status",
      dataIndex: "role_list", // Assuming the key in your data for process_roles is "process_roles"
      key: "process_roles",
      width: "20%",
      align: "center",
      render: (processRoles) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Stack direction="row" spacing={1}>
              {processRoles &&
                processRoles?.some(
                  (role) => role.role_name === "Production Manager"
                ) && (
                  <Tooltip
                    title={
                      processRoles?.find(
                        (role) => role.role_name === "Production Manager"
                      ).approval_status === "approve"
                        ? "Approved"
                        : "Approval Pending"
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            processRoles?.find(
                              (role) => role.role_name === "Production Manager"
                            ).approval_status === "approve"
                              ? true
                              : false
                          }
                        />
                      }
                      label="Production"
                    />
                  </Tooltip>
                )}
              {processRoles &&
                processRoles?.some((role) => role.role_name === "R&D") && (
                  <Tooltip
                    title={
                      processRoles?.find((role) => role.role_name === "R&D")
                        .approval_status === "approve"
                        ? "Approved"
                        : "Approval Pending"
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            processRoles?.find(
                              (role) => role.role_name === "R&D"
                            ).approval_status === "approve"
                              ? true
                              : false
                          }
                        />
                      }
                      label="R&D"
                    />
                  </Tooltip>
                )}
              {processRoles &&
                processRoles?.some((role) => role.role_name === "QA") && (
                  <Tooltip
                    title={
                      processRoles?.find((role) => role.role_name === "QA")
                        .approval_status === "approve"
                        ? "Approved"
                        : "Approval Pending"
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            processRoles?.find(
                              (role) => role.role_name === "QA"
                            ).approval_status === "approve"
                              ? true
                              : false
                          }
                        />
                      }
                      label="QA"
                    />
                  </Tooltip>
                )}
            </Stack>
          </div>
        </>
      ),
    },
    ...get_view_function(),
    {
      title: "Material Balance",
      dataIndex: "",
      key: "duration",
      width: "10%",
      align: "center",
      render: (data, record, index) => {
        return (
          <IconButton
            onClick={() =>
              navigate("/bal-material", {
                state: { batch_number: record?.batch_number || "" },
              })
            }
          >
            <WysiwygIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const recipe_details_columns = [
    {
      title: "Recipe",
      dataIndex: "recipe_name",
      key: "id",
      align: "center",
    },
    {
      title: "Batch",
      dataIndex: "batch_number",
      key: "id",
      align: "center",
    },
    {
      title: "Scheduled Time",
      dataIndex: "batch_schedule_time",
      key: "batch_schedule_time",
      align: "center",
      render: (data) => {
        // return formatDate(data);\
        return data?.slice(0, 16);
      },
    },
    {
      title: "Batch Size",
      dataIndex: "batch_size",
      key: "batch_size",
      align: "center",
    },
    {
      title: "Machine",
      dataIndex: "machine",
      key: "machine",
      align: "center",
    },
    {
      title: "Start Time",
      dataIndex: "batch_start_time",
      key: "id",
      align: "center",
      render: (data) => {
        // return formatDate(data);
        return data?.slice(0, 16);
      },
    },
    ...get_view_function1(),
    // {
    //   title: "Edit Batch",
    //   dataIndex: "",
    //   key: "batch_schedule_time",
    //   align: "center",
    //   render: (data, record, index) => {
    //     return (
    //       <>
    //         <Button
    //           onClick={() => {
    //             navigate("/edit-batch", {
    //               state: {
    //                 batch_number: record?.batch_number,
    //                 recipe: 242,
    //                 recipeName: record?.recipe_name,
    //               },
    //             });
    //           }}
    //         >
    //           Edit
    //         </Button>
    //       </>
    //     );
    //   },
    // },
  ];

  const batch_start_columns = [
    {
      title: "Recipe",
      dataIndex: "recipe_name",
      key: "id",
      align: "center",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record.recipe_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.batch_number)
            .toLowerCase()
            .includes(value.toLowerCase())
          // String(record.status).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Batch",
      dataIndex: "batch_number",
      key: "id",
      align: "center",
    },
    {
      title: "Batch Size",
      dataIndex: "batch_size",
      key: "batch_size",
      align: "center",
    },
    {
      title: "Machine",
      dataIndex: "machine",
      key: "machine",
      align: "center",
    },
    {
      title: "Scheduled Time",
      dataIndex: "batch_schedule_time",
      key: "batch_schedule_time",
      align: "center",
      render: (data) => {
        // return formatDate(data);
        return data?.slice(0, 16);
      },
    },
    {
      title: "Start",
      key: "id",
      align: "center",
      render: (data, record, index) => {
        return (
          <Button
            size="small"
            variant="outlined"
            disabled={!record?.active_status}
            startIcon={<PlayCircleFilledIcon />}
            onClick={() => {
              bp_update_starttime_for_batch_number(
                {
                  batch_id: record?.batch_id,
                },
                (res) => {
                  console.log(res);
                  if (res.status === "failed") {
                    toast.warning(res.message);
                  } else {
                    toast.success(res.message);
                    get_all_batchDetails();
                  }
                }
              );
            }}
          >
            Start
          </Button>
        );
      },
    },
  ];

  const add_schedule = () => (
    <Scheduling
      refresh={refresh}
      set_refresh={set_refresh}
      selectedButton={selectedButton}
    />
  );

  const get_all_batchDetails = async () => {
    try {
      // await bp_get_batch_details(
      //   success_bp_get_batch_details,
      //   selectedButton === "production" ? { recipe_type: true } : {}
      // );

      const req =
        selectedButton === "production"
          ? {
              recipe_type: true,
              start_time: fromDate,
              end_time: toDate,
              page_number: page_number,
              ...(searchvalue && { search: searchvalue }),
            }
          : fromDate && toDate
          ? {
              start_time: fromDate,
              end_time: toDate,
              page_number: page_number,
              ...(searchvalue && { search: searchvalue }),
            }
          : {
              page_number: page_number,
              ...(searchvalue && { search: searchvalue }),
            };

      set_loading(true);
      if (tab_value === "1") {
        await get_schedule_batch_details(req, success_bp_get_batch_details);
      } else if (tab_value === "2") {
        await get_running_batch_details(req, success_bp_get_batch_details);
      } else if (tab_value === "3") {
        await get_completed_batch_details(req, success_bp_get_batch_details);
      } else {
        await get_batch_copy_details(success_bp_get_copy_details);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_copy_details = (res) => {
    if (res?.data?.status === "success") {
      setModifiedData(res?.data?.data);
      set_loading(false);
    } else {
      set_loading(false);
    }
  };

  const success_bp_get_batch_details = (res) => {
    if (res?.data?.status === "success") {
      set_batch_data(res?.data?.data[0]);
      setTotal(res?.data?.obj_count);
      set_loading(false);
    } else {
      set_loading(false);
    }
  };

  const get_depart_wise_employee = async (opId) => {
    try {
      bp_get_depart_wise_employee(opId, success_bp_get_depart_wise_employee);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_depart_wise_employee = (res) => {
    set_dept_wise_emp(res?.data?.data);
  };

  const get_batch_changesDetails = async (recipe_id) => {
    try {
      await get_batch_changes_details(
        { recipe_id: recipe_id },
        success_bp_get_batch_changes_details
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_batch_changes_details = (res) => {
    set_modified_details(addKeysInFilterDataForCollapse(res?.data?.data));
  };

  useEffect(() => {
    set_user_access_data(JSON.parse(localStorage.getItem("user_access_data")));
    get_employee_list();
  }, []);

  useEffect(() => {
    get_all_batchDetails();
  }, [refresh, selectedButton, tab_value, page_number, range_value]);

  useEffect(() => {
    if (selectedProcessId && current_batch) {
      if (tab_value === "2") {
        get_batch_details_by_process(selectedProcessId, current_batch);
      } else {
        //this is for refresh the scheduling while assigning or reassigning
        set_showSummary(false);
        get_batch_process_by_schedule(current_batch, currentExpandedbatchId);
        get_all_batchDetails();
        set_showSummary(true);
      }
    }
  }, [assignStatus]);

  const view_operation_function = (process, batch) => {
    console.log(process);
    set_selectedProcessId(process?.process_id);
    set_selectedProcessName(process?.process_number);
    // get_batch_details_by_process(process?.process_id, batch || current_batch);
    set_showSummary(true);
  };

  const reset_date_range = () => {
    set_range_value([]);
    setFromDate();
    setToDate();
  };

  const timeline = (reportData, comStatus) => {
    return (
      <>
        <Grid item xs={12} lg={12}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h4">{selected_recipe}</Typography>
              <Typography variant="h4">{`${"-"}`}</Typography>
              <Typography variant="h4">
                {`${"("}` + current_batch + `${")"}`}
              </Typography>
            </Stack>
            <Tooltip title="Back">
              <IconButton onClick={() => setReportView(false)}>
                <ArrowBackIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
            height: "68vh",
            overflowY: "scroll",
          }}
        >
          <Timeline
            items={reportData?.map((process, index_1) => ({
              //color: process?.operations?.length > 0 ? "green" : "red",
              color:
                process?.progress === "Completed"
                  ? "green"
                  : process?.progress === "In Process"
                  ? "orange"
                  : process?.progress === "Pending" && "red",
              // dot: (
              //   <ClockCircleOutlined
              //     style={{ color: "#FAAE2F", fontSize: "16px" }}
              //   />
              // ),

              children: (
                <>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Typography>
                      <b>{"Process: "}</b>
                      {`${process?.process_number}`}
                    </Typography>

                    {/* {process?.employee && process?.employee?.length > 0 && (
                      <Typography>
                        {`${"Approved By: "}`}
                        {process?.employee?.length > 0
                          ? process.employee.map((emp) => (
                              <Box
                                key={emp?.employee_id}
                                display="inline"
                                mr={1}
                              >
                                <Tag>{emp?.employee_name}</Tag>
                              </Box>
                            ))
                          : "No Approvers"}
                      </Typography>
                    )} */}

                    {
                      // !process?.operations?.length > 0 ? (
                      //   <Tag color="red">{"Pending"}</Tag>
                      // ) : !showSummary ? (
                      showSummaryIndex !== index_1 ? (
                        <Tooltip title="View Operations">
                          <IconButton
                            onClick={() => {
                              set_dag_nodes([`${process?.op_id}`]);
                              view_operation_function(process);
                              set_showSummaryIndex(index_1);
                            }}
                          >
                            <VisibilityIcon
                              color={
                                process?.progress === "Completed"
                                  ? "success"
                                  : process?.progress === "In Process"
                                  ? "warning"
                                  : process?.progress === "Pending" && "error"
                              }
                              style={{ fontSize: "20px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Close">
                          <IconButton
                            onClick={() => {
                              set_showSummary(false);
                            }}
                          >
                            <CloseIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  </Stack>
                </>
              ),
            }))}
          />
        </Grid>
      </>
    );
  };

  const filterRecipe = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              onClick={() => handleClick("test")}
              style={buttonStyles(selectedButton === "test")}
            >
              Test
            </Button>
            <Button
              onClick={() => handleClick("production")}
              style={buttonStyles(selectedButton === "production")}
            >
              Production
            </Button>
          </ButtonGroup>
        </Box>
        <RangePicker
          format="YYYY-MM-DD"
          size="large"
          value={range_value}
          onChange={(value, dateString) => {
            setFromDate(dateString[0]);
            setToDate(dateString[1]);
            set_range_value(value);
          }}
        />
      </Stack>
    );
  };

  const [dag_batch, set_dag_batch] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const reset_flow = () => {
    set_dag_batch(null);
    setSelectedRowKeys([]);
  };

  return (
    <MainCard>
      <Grid container spacing={1} style={{ display: "flex" }}>
        {dag_batch && (
          <Grid item xs={12} lg={12}>
            <DAGFlowCopy data={dag_batch} set_flow_data={reset_flow} />
          </Grid>
        )}
        <Grid item xs={12} lg={12}>
          <Tabs
            activeKey={tab_value}
            tabBarExtraContent={
              <React.Fragment>
                <Stack direction="row" spacing={1}>
                  <div>
                    <Input.Search
                      Placeholder="Search here..."
                      className="mb-1"
                      value={searchvalue}
                      onSearch={(value) => {
                        get_all_batchDetails();
                      }}
                      onChange={(e) => setSearchvalue(e.target.value)}
                    />
                  </div>

                  {tab_value === "1" && (
                    <Tooltip
                      title={!show_add_schedule ? "Schedule Batch" : "Close"}
                    >
                      <IconButton
                        onClick={() => {
                          set_show_add_schedule(!show_add_schedule);
                        }}
                      >
                        {!show_add_schedule ? (
                          <AddIcon sx={{ fontSize: "32px" }} color="primary" />
                        ) : (
                          <CloseIcon
                            sx={{ fontSize: "32px" }}
                            color="primary"
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}

                  <InfoComponent infoText={"view Recipe"} />
                </Stack>
              </React.Fragment>
            }
            onChange={(key) => {
              set_page_number(1);
              set_dag_batch();
              setSelectedRowKeys([]);
              setSearchvalue("");
              setReportView(false);
              set_tab_value(key);
              set_show_add_schedule(false);
              reset_date_range();
            }}
            items={[
              {
                key: "1",
                label: "Scheduling",
                children: (
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                      {filterRecipe()}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Stack spacing={1}>
                        {show_add_schedule && add_schedule()}
                        <Table
                          size="small"
                          bordered
                          pagination={false}
                          columns={batch_start_columns}
                          loading={loading}
                          expandable={{
                            expandedRowRender: expandedRowRender,
                            onExpand: handleExpand,
                            expandedRowKeys: [current_expand],
                          }}
                          dataSource={addKeysInFilterDataForCollapse(
                            batch_data?.schedule_list
                          )}
                          //scroll={{ x:600, y: 450 }}
                        />
                        <Stack direction="row" justifyContent="end">
                          <Pagination
                            total={total}
                            showTotal={(total) => `Total ${total} Recipe's`}
                            pageSize={10}
                            current={page_number}
                            showSizeChanger={false}
                            onChange={(value) => {
                              set_page_number(value);
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                ),
              },
              {
                key: "2",
                label: "Running",
                children: (
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                      {filterRecipe()}
                    </Grid>
                    <Grid item xs={12} lg={reportView ? 9 : 12}>
                      <Stack spacing={1}>
                        {show_add_schedule && add_schedule()}
                        <Table
                          size="small"
                          bordered
                          loading={loading}
                          pagination={false}
                          columns={recipe_details_columns}
                          dataSource={batch_data?.running_list?.map(
                            (item, index) => ({ ...item, key: index })
                          )}
                          // scroll={{ x: 950, y: 450 }}
                          rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys, selectedRows) => {
                              set_dag_batch();
                              setTimeout(() => {
                                set_dag_batch(
                                  selectedRows[selectedRows?.length - 1]
                                );
                                setSelectedRowKeys([
                                  selectedRowKeys[selectedRowKeys?.length - 1],
                                ]);
                              }, 1);
                            },
                            selectedRowKeys: selectedRowKeys,
                            hideSelectAll: true,
                          }}
                        />
                        <Stack direction="row" justifyContent="end">
                          <Pagination
                            total={total}
                            showTotal={(total) => `Total ${total} Recipe's`}
                            pageSize={10}
                            current={page_number}
                            showSizeChanger={false}
                            onChange={(value) => {
                              set_page_number(value);
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    {reportView && (
                      <Grid container item xs={12} lg={3} spacing={1}>
                        {timeline(reportData)}
                      </Grid>
                    )}
                  </Grid>
                ),
              },
              {
                key: "3",
                label: "Completed",
                children: (
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={12}>
                      {filterRecipe()}
                    </Grid>
                    <Grid item xs={12} lg={reportView ? 9 : 12}>
                      <Stack spacing={1}>
                        {show_add_schedule && add_schedule()}
                        <Table
                          size="small"
                          bordered
                          loading={loading}
                          pagination={false}
                          columns={completed_Batch_columns}
                          dataSource={batch_data?.completed_list?.map(
                            (item, index) => ({ ...item, key: index })
                          )}
                          // scroll={{ x: 950, y: 450 }}
                          rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys, selectedRows) => {
                              set_dag_batch();
                              setTimeout(() => {
                                set_dag_batch(
                                  selectedRows[selectedRows?.length - 1]
                                );
                                setSelectedRowKeys([
                                  selectedRowKeys[selectedRowKeys?.length - 1],
                                ]);
                              }, 1);
                            },
                            selectedRowKeys: selectedRowKeys,
                            hideSelectAll: true,
                          }}
                        />
                        <Stack direction="row" justifyContent="end">
                          <Pagination
                            total={total}
                            showTotal={(total) => `Total ${total} Recipe's`}
                            pageSize={10}
                            current={page_number}
                            showSizeChanger={false}
                            onChange={(value) => {
                              set_page_number(value);
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    {reportView && (
                      <Grid container item xs={12} lg={3} spacing={1}>
                        {timeline(reportData, "green")}
                      </Grid>
                    )}
                  </Grid>
                ),
              },
              {
                key: "4",
                label: "Modified Batches",
                children: (
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={reportView ? 9 : 12}>
                      <Stack spacing={1}>
                        {show_add_schedule && add_schedule()}
                        <Table
                          size="small"
                          bordered
                          loading={loading}
                          pagination={false}
                          columns={modified_Batch_columns}
                          dataSource={modifiedData}
                        />
                        <Stack direction="row" justifyContent="end">
                          <Pagination
                            total={total}
                            showTotal={(total) => `Total ${total} Recipe's`}
                            pageSize={10}
                            current={page_number}
                            showSizeChanger={false}
                            onChange={(value) => {
                              set_page_number(value);
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
      <Modal
        visible={isVisible}
        title={"Modified Operation"}
        width={1200}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="mt-4">
          <Table
            dataSource={modified_details}
            columns={processColumns}
            pagination={false}
            showHeader={true}
            expandable={{
              expandedRowRender: expandedRowRenderCols,
            }}
          />
        </div>
      </Modal>
      <OperationsSkeleton
        show={showSummary}
        report={processOperations || []}
        setShow={set_showSummary}
        scheduletime={scheduleTimeStatus}
        selected_recipe={selected_recipe}
        tab={tab_value}
        setAssignStatus={setAssignStatus}
        assignStatus={assignStatus}
        selectedProcessId={selectedProcessId}
        current_batch={current_batch}
        set_selectedProcessId={set_selectedProcessId}
        selectedProcessName={selectedProcessName}
        set_showSummaryIndex={set_showSummaryIndex}
        bulk_assign_list={bulk_assign_list}
      />
    </MainCard>
  );
}

export default ScheduledBatches;
