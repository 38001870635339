import MainCard from "components/MainCard";
import React, { useState } from "react";
import {
  Steps,
  Descriptions,
  Table,
  Empty,
  Timeline,
  Button as AntButton,
  Badge,
} from "antd";
import {
  IconButton,
  Stack,
  Typography,
  Chip,
  Tooltip,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { get_batch_report_pdf } from "utils/api";
import { base64ToFile } from "pages/reusable_functions";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "../.././assets/styles/expand.css";

const deviation_color = (per) => {
  let color;
  let bg_color;

  switch (true) {
    case per >= 0 && per <= 5:
      color = "green";
      bg_color = "#CCFFCC ";
      break;
    case (per >= -10 && per < 0) || (per > 5 && per <= 50):
      color = "orange";
      bg_color = "#FFF59E";
      break;
    case per < -10 || per > 50:
      color = "red";
      bg_color = "#FFCCCC";
      break;
    default:
      color = "black";
  }
  return { color: color, bg_color: bg_color };
};

function ReportSkeleton({ report }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const report2 = state?.report;
  const batch_number = state?.redirect_batch;

  const expandedRowRender = (record) => {
    let detailsRemarks = record?.detailsRemarks;
    return (
      <Stack spacing={1}>
        {detailsRemarks?.batch_ingredients?.length > 0 && (
          <>
            <Table
              title={() => (
                <Typography variant="h5">Batch Ingredients</Typography>
              )}
              dataSource={detailsRemarks?.batch_ingredients}
              size="small"
              columns={[
                {
                  title: "Date-Time",
                  dataIndex: "created_on",
                  key: "created_on",
                  render: (created_on) => created_on?.slice(0, 19),
                  width: "20%",
                },
                {
                  title: "Ingredient",
                  dataIndex: "ingredients_name",
                  key: "ingredients_name",
                  render: (_, record) =>
                    `${record?.ingredients_name} (${record?.unit})`,
                  width: "20%",
                },
                {
                  title: "Expected ",
                  dataIndex: "expected_quantity",
                  key: "expected_quantity",
                  width: "20%",
                },
                {
                  title: "Actual",
                  dataIndex: "quantity",
                  key: "quantity",
                  width: "20%",
                },
                {
                  title: "Deviation",
                  dataIndex: "quantity_deviation",
                  key: "quantity_deviation",
                  width: "20%",
                  render: (_, record) => record?.quantity_deviation + " %",
                  onCell: (record) => ({
                    style: {
                      backgroundColor: deviation_color(
                        record?.quantity_deviation
                      )["bg_color"],
                      color: deviation_color(record?.quantity_deviation)[
                        "color"
                      ],
                    },
                  }),
                },
              ]}
              pagination={false}
              bordered
            />
          </>
        )}

        {/* {detailsRemarks?.machine_parameters_value?.length > 0 && (
        <>
          <Typography variant="h5">Machines</Typography>
          <Descriptions
            column={1}
            size="small"
            bordered
            items={[
              {
                key: 1,
                label: "Machines",
                children: detailsRemarks?.machine_parameters_value?.map(
                  (item) => (
                    <Chip
                      size="small"
                      sx={{ m: 0.5 }}
                      label={item?.equipment}
                    />
                  )
                ),
              },
            ]}
          />
        </>
      )} */}

        {/* {detailsRemarks?.sampling?.length > 0 && (
        <>
          <Typography variant="h5">Sampling</Typography>
          <Table
            dataSource={detailsRemarks?.sampling}
            size="small"
            columns={[
              {
                title: "Material",
                dataIndex: "material_inventory_name",
                key: "material_inventory_name",
              },
              {
                title: "Testing Type",
                dataIndex: "testing_type_name",
                key: "testing_type_name",
              },
              {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
                render: (_, record) =>
                  `${record?.quantity} ${record?.material_inventory_unit}`,
              },
            ]}
            pagination={false}
            bordered
          />
        </>
      )} */}

        {detailsRemarks?.batch_sample_value?.length > 0 && (
          <>
            <Table
              title={() => <Typography variant="h5">Sampling</Typography>}
              dataSource={detailsRemarks?.batch_sample_value}
              size="small"
              columns={[
                {
                  title: "Ingredients",
                  dataIndex: "material_inventory_name",
                  key: "ingredients",
                  render: (_, record) =>
                    `${record?.material_inventory_name} (${record?.material_inventory_unit})`,
                },
                {
                  title: "Testing Type",
                  dataIndex: "testing_type_name",
                  key: "testing_type",
                },
                {
                  title: "Sample Number",
                  dataIndex: "sample_number",
                  key: "sample_number",
                },
                {
                  title: "Date",
                  dataIndex: "created_on",
                  key: "created_on",
                  render: (created_on) => created_on?.slice(0, 19),
                },
                {
                  title: "Quantity",
                  dataIndex: "quantity",
                  key: "quantity",
                },
              ]}
              pagination={false}
              bordered
            />
          </>
        )}

        {detailsRemarks?.batch_parameters?.length > 0 && (
          <>
            <Table
              title={() => (
                <Typography variant="h5"> Monitoring Parameters</Typography>
              )}
              dataSource={detailsRemarks?.batch_parameters}
              size="small"
              columns={[
                {
                  title: "Date-Time",
                  dataIndex: "created_on",
                  key: "created_on",
                  render: (created_on) => created_on?.slice(0, 19),
                },
                {
                  title: "Parameter",
                  dataIndex: "parameter_name",
                  key: "parameter_name",
                  render: (_, record) =>
                    `${record?.parameter_name} (${record?.parameter_unit})`,
                },
                {
                  title: "Values",
                  dataIndex: "value",
                  key: "value",
                },
              ]}
              pagination={false}
              bordered
            />
          </>
        )}
        {/* 
      {detailsRemarks?.process_products?.length > 0 && (
        <>
          <Typography variant="h5">Process Products</Typography>
          <Table
            dataSource={detailsRemarks?.process_products}
            size="small"
            columns={[
              {
                title: "Product",
                dataIndex: "product_name",
                key: "product_name",
                render: (_, record) =>
                  `${record?.product_name} (${record?.product_name_unit})`,
              },
              {
                title: "Expected Values",
                dataIndex: "value",
                key: "process_expec",
                render: (value, record) =>
                  `${record?.process_expec_min_value} - ${record?.process_expec_max_value}`,
              },
              {
                title: "Therotical Value",
                dataIndex: "process_thero_quantity",
                key: "process_thero_quantity",
              },
            ]}
            pagination={false}
            bordered
          />
        </>
      )} */}

        {detailsRemarks?.batch_product_inventory?.length > 0 && (
          <>
            <Table
              title={() => (
                <Typography variant="h5">Process Products</Typography>
              )}
              dataSource={detailsRemarks?.batch_product_inventory}
              size="small"
              columns={[
                {
                  title: "Product",
                  dataIndex: "product_name",
                  key: "product_name",
                },
                {
                  title: "Container No",
                  dataIndex: "Container No",
                  key: "Container No",
                },
                {
                  title: "Tare Wt",
                  dataIndex: "Tare Wt.",
                  key: "Tare Wt.",
                },
                {
                  title: "Gross Wt",
                  dataIndex: "Gross Wt.",
                  key: "Gross Wt.",
                },
                {
                  title: "Net Wt",
                  dataIndex: "Net Wt.",
                  key: "Net Wt.",
                },
              ]}
              pagination={false}
              bordered
            />
          </>
        )}
      </Stack>
    );
  };

  const columns = [
    {
      title: "Op No",
      dataIndex: "opNo",
      key: "opNo",
      width: "7%",
      align: "center",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      key: "operation",
      width: "22%",
      render: (operation, record) =>
        record?.deviation_status ? (
          <Badge color="red" text={operation} />
        ) : (
          operation
        ),
    },
    {
      title: "Equipment",
      dataIndex: "equipmentNo",
      key: "equipmentNo",
      align: "center",
      width: "20%",
      render: (equipmentNo, record) => {
        return (
          <Stack>
            <Typography variant="h6">{record?.equipmentNo} </Typography>
            <Typography variant="body">
              {record?.detailsRemarks?.machine_parameters_value?.length > 0 &&
                `(${record?.detailsRemarks?.machine_parameters_value
                  ?.map((item) => item?.equipment)
                  ?.join(", ")})`}
            </Typography>
          </Stack>
        );
      },
    },
    {
      title: "From",
      dataIndex: "timeFrom",
      key: "timeFrom",
      align: "center",
      width: "12%",
      render: (timeFrom) => timeFrom?.slice(0, 19),
    },
    {
      title: "To",
      dataIndex: "timeTo",
      key: "timeTo",
      align: "center",
      width: "12%",
      render: (timeFrom) => timeFrom?.slice(0, 19),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      align: "center",
      width: "12%",
      render: (dataIndex) => dataIndex?.split(".")[0],
    },
    // {
    //   title: "Details / Remarks",
    //   dataIndex: "detailsRemarks",
    //   key: "detailsRemarks",
    //   render: (detailsRemarks) => {
    //     return (

    //     );
    //   },
    // },
    {
      title: "Deviation",
      dataIndex: "deviation",
      key: "deviation",
      width: "15%",
      render: (_, record) => {
        return (
          <Typography sx={{ p: 1 }}>
            <strong> Time :</strong> {record?.time_deviation}
          </Typography>
        );
      },
    },
    // {
    //   title: "Performed By",
    //   dataIndex: "performed_by",
    //   key: "performed_by",
    //   width: "8%",
    //   align: "center",
    // },
  ];

  const [show, set_show] = useState([]);
  const [current, set_current] = useState("");
  const [expanded, set_expanded] = useState([]);
  return (
    <MainCard
    // title={report2 ? "Batch Details" : ""}
    // backbutton={report2 ? true : false}
    >
      {report2 && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">Batch Details</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<FileDownloadIcon />}
              onClick={() => {
                get_batch_report_pdf(batch_number, (res) => {
                  base64ToFile(res?.data?.data, `${batch_number}_report`);
                });
              }}
            >
              Download
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Stack>
        </Stack>
      )}
      <div style={{ padding: "10px" }}>
        <Timeline
          style={{
            width: "100%",
          }}
          mode="left"
          items={(report || report2)
            ?.filter((process) => process?.operations?.length !== 0)
            ?.map((process, index) => {
              return {
                dot: !show[index] ? (
                  <Tooltip title="View Operations">
                    <AntButton
                      type="link"
                      onClick={() => {
                        let temp = [...show];
                        temp[index] = true;
                        set_show(temp);
                        set_current(index);
                      }}
                      icon={<RightOutlined />}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Close">
                    <AntButton
                      type="link"
                      onClick={() => {
                        let temp = [...show];
                        temp[index] = false;
                        set_show(temp);
                      }}
                      icon={<DownOutlined />}
                    />
                  </Tooltip>
                ),
                children: (
                  <>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="h5" color="secondary">
                        Process:
                      </Typography>
                      <Typography variant="h5">{`${process?.process_number}`}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Typography color="secondary">
                        {process?.employee?.length > 0
                          ? "Approval by: "
                          : "Approval Status: "}
                      </Typography>
                      {process?.employee?.length > 0 ? (
                        <>
                          {process?.employee?.map((item) => (
                            <Chip
                              size="small"
                              variant="outlined"
                              sx={{ m: 0.5 }}
                              label={`${item?.employee_name} - ${item?.role_name}`}
                            />
                          ))}
                        </>
                      ) : (
                        <Typography color="warning">Pending</Typography>
                      )}
                    </Stack>

                    <Stack>
                      {show[index] &&
                        (process?.operations?.length > 0 ? (
                          <Table
                            dataSource={process?.operations?.map(
                              (item, index) => ({ ...item, key: index })
                            )}
                            size="small"
                            columns={columns}
                            pagination={false}
                            bordered
                            scroll={{ x: 100, y: 480 }}
                            rowClassName={(record, index) => {
                              if (expanded?.includes(index)) {
                                return "col";
                              }
                            }}
                            expandable={{
                              expandedRowRender,
                              onExpandedRowsChange: (expanded) =>
                                set_expanded(expanded),
                              expandedRowClassName: () => "col2",
                            }}
                          />
                        ) : (
                          <Empty />
                        ))}
                    </Stack>
                  </>
                ),
              };
            })}
        />
      </div>
    </MainCard>
  );
}

export default ReportSkeleton;
