import React, { useState, useEffect } from "react";

// import { DashboardOutlined } from '@ant-design/icons';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ApprovalIcon from "@mui/icons-material/Approval";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SpeedIcon from "@mui/icons-material/Speed";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import WorkHistoryIcon from "@mui/icons-material/WorkHistoryOutlined";
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { ControlOutlined } from "@ant-design/icons";
// icons
const icons = {
  DashboardOutlinedIcon,
  SchemaOutlinedIcon,
  CasinoOutlinedIcon,
  ConfirmationNumberOutlinedIcon,
  CategoryOutlinedIcon,
  PersonOutlineIcon,
  SettingsOutlinedIcon,
  PermDataSettingIcon,
  AssignmentTurnedInOutlinedIcon,
  EventAvailableIcon,
  SpeedIcon,
  ApprovalIcon,
  NotificationsActiveIcon,
  ArticleIcon,
  ContentPasteIcon,
  WorkHistoryIcon,
  ScienceOutlinedIcon,
  ControlOutlined
};

// ==============================|| MENU ITEMS ||============================== //

function Dashboard() {
  const [access_list_data, setaccess_list_data] = useState([]);
  const [refresh, set_refresh] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("user_access_data");
    if (storedData) {
      setaccess_list_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  return [
    {
      id: "group-dashboard",
      title: "Menu",
      type: "group",
      children: [
        {
          id: "DashBoard",
          title: "DashBoard",
          type: "item",
          icon: icons.SchemaOutlinedIcon,
          url: "http://89.116.20.193:8088/superset/dashboard/11",
          target: "_blank", // Open in a new tab
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Dashboard"
          )?.read_access,
        },
        {
          id: "Recipe",
          title: "Recipe",
          type: "item",
          icon: icons.DashboardOutlinedIcon,
          url: "/viewRecipe",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Recipe"
          )?.read_access,
        },
        {
          id: "Batches",
          title: "Batches",
          type: "item",
          icon: icons.ContentPasteIcon,
          url: "/batches",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Batches"
          )?.read_access,
        },
        {
          id: "result",
          title: "Result",
          type: "item",
          icon: icons.ArticleIcon,
          url: "/result",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Result"
          )?.read_access,
        },
        {
          id: "batch-approval",
          title: "Batch Approval",
          type: "item",
          icon: icons.ApprovalIcon,
          url: "/batch-approval",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Batch Approval"
          )?.read_access,
        },
        {
          id: "sample_testing",
          title: "Sample Testing",
          type: "item",
          icon: icons.ScienceOutlinedIcon,
          url: "/sample-testing",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Sample Testing"
          )?.read_access,
        },
        {
          id: "notification",
          title: "Notifications & Alerts",
          type: "item",
          url: "/notifications",
          icon: icons.NotificationsActiveIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "Configration",
          title: "Configuration",
          type: "item",
          icon: icons.SettingsOutlinedIcon,
          url: "/configuration",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Configuration"
          )?.read_access,
        },
        {
          id: "userConfig",
          title: "User Config",
          type: "item",
          icon: icons.PersonOutlineIcon,
          url: "/userConfig",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "User Configuration"
          )?.read_access,
        },
        {
          id: "shift-details",
          title: "Shift Details",
          type: "item",
          icon: icons.WorkHistoryIcon,
          url: "/shift-details",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "User Configuration"
          )?.read_access,
        },
        {
          id: "audit-report",
          title: "Audit Report",
          type: "item",
          icon: icons.ArticleIcon,
          url: "/audit-report",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "User Configuration"
          )?.read_access,
        },

        {
          id: "Access Control",
          title: "Access Control",
          type: "item",
          icon: icons.ControlOutlined,
          url: "/accessControl",
          breadcrumbs: true,
          showMenu: access_list_data?.find(
            (item) => item.module_name === "Access Control"
          )?.read_access,
        },
      ].filter(Boolean),
    },
  ];
}

export default Dashboard;
