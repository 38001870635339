import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Grid, Box, Tab, IconButton, Tooltip,Stack } from "@mui/material";
import { Modal, Tabs } from "antd";
import MainCard from "components/MainCard";
import RecipeApproval from "./RecipeApproval";
import RecipeViewApproval from "./RecipeViewApproval";
import InfoComponent from "components/Info";

function Approvals() {
  const [user_access_data, set_user_access_data] = useState([]);

  useEffect(() => {
    set_user_access_data(JSON.parse(localStorage.getItem("user_access_data")));
  }, []);

  const [tab_value, set_tab_value] = useState("1");

  return (
    <MainCard>
      <Tabs
        //activeKey={tab_value}
        // tabBarExtraContent={
        //   user_access_data?.find((item) => item.module_name === "Configuration")
        //     ?.create_access && (
        //     <React.Fragment>
        //       {!show_config ? (
        //         <Tooltip title="Add">
        //           <IconButton
        //             onClick={() => {
        //               set_show_config(true);
        //             }}
        //           >
        //             <AddIcon sx={{ fontSize: "32px" }} color="primary" />
        //           </IconButton>
        //         </Tooltip>
        //       ) : (
        //         <Tooltip title="Close">
        //           <IconButton
        //             onClick={() => {
        //               set_show_config(false);
        //             }}
        //           >
        //             <CloseIcon sx={{ fontSize: "32px" }} color="primary" />
        //           </IconButton>
        //         </Tooltip>
        //       )}
        //     </React.Fragment>
        //   )
        // }
        onChange={(key) => {
          set_tab_value(key);
        }}

        tabBarExtraContent={
          <React.Fragment>
            <Stack direction="row" spacing={1}>
            <InfoComponent infoShow={true} infoText={"view Recipe"} />
              </Stack>
              </React.Fragment>
              }
        items={[
          user_access_data?.find(
            (item) => item.module_name === "Batch Approval"
          )?.read_access && !user_access_data?.find(
            (item) => item.module_name === "Batch Approval"
          )?.update_access &&
          {
            key: "1",
            label: "View  Batches",
            children: <RecipeViewApproval />,
          },
          user_access_data?.find(
            (item) => item.module_name === "Batch Approval"
          )?.update_access && 
          {
            key: "2",
            label: "Batch Approval",
            children: <RecipeApproval />,
          },
        ]}
      />
    </MainCard>
  );
}

export default Approvals;
