import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import NotificationPage from "components/NotificationPage";
import Calender from "components/Calender";
import RecipeConfig from "pages/Batch-Processing/RecipeConfig";
import Batch from "pages/Batch-Processing/Recipe/Batch";
import Report from "pages/Batch-Processing/Report";
import AddIngredient from "pages/Batch-Processing/Recipe/AddIngredient";
import AddData from "pages/Batch-Processing/Recipe/AddData";
import Product from "pages/Batch-Processing/Recipe/Product";
import SampleTesting from "pages/Batch-Processing/Recipe/SampleTesting";
import ScheduledBatches from "pages/Batch-Processing/Recipe/ScheduledBatches";
import AddSample from "pages/Batch-Processing/Recipe/AddSample";
import ReportSkeleton from "pages/Batch-Processing/ReportSkeleton";
import UserConfig2 from "pages/Batch-Processing/Configurations/UserConfig2";
import RecipeApproval from "pages/Batch-Processing/Recipe/RecipeApproval";
import Approvals from "pages/Batch-Processing/Recipe/Approvals";
import GenerateQRs from "pages/Batch-Processing/Configurations/GenerateQRs";
import Shift from "pages/Batch-Processing/shift-details/Shift";
import DAGFlowCopy from "pages/dashboard/DAGFlow copy";
import ScheduleNotificationPage from "components/ScheduleNotificationPage";
import GrafanaDashboard1 from "pages/dashboard/GrafanaDashboard1";
import ProcessCreationDAG from "pages/simulator/ProcessCreationDAG";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

// const MachineDetails = Loadable(lazy(() => import('pages/Preventive-maintenance/machine-details/MachineDetails')));

const Configuration = Loadable(
  lazy(() => import("pages/Batch-Processing/Configuration"))
);

const AddRecipe = Loadable(lazy(() => import("pages/Batch-Processing/Recipe")));

const ViewRecipe = Loadable(
  lazy(() => import("pages/Batch-Processing/ViewRecipe"))
);

const AddNRecipe = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/AddRecipeNew"))
);

const Scheduling = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/Scheduling"))
);

const UserConfig = Loadable(
  lazy(() => import("pages/Batch-Processing/Configurations/UserConfig"))
);

const AccessControl = Loadable(
  lazy(() => import("pages/Batch-Processing/Configurations/AccessControl"))
);

const Result = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/Result"))
);

const RunJob = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/RunJob"))
);

const AddRecipeForm = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/AddRecipeForm"))
);

const AllProcessesByRecipeId = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/PendingRecipe"))
);

const RecipeMetaData = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/RecipeMetaData"))
);

const ApproveProcess = Loadable(
  lazy(() => import("pages/Batch-Processing/ApproveProcess"))
);

const EditRecipe = Loadable(
  lazy(() => import("pages/Batch-Processing/Recipe/EditRecipe"))
);
const Audit = Loadable(lazy(() => import("pages/Batch-Processing/Audit")));
const UserProfile = Loadable(lazy(() => import("components/UserProfile")));

const BOM = Loadable(lazy(() => import("pages/Batch-Processing/BOM")));
const BalanceMaterial = Loadable(lazy(() => import("pages/Batch-Processing/MaterialBalance")));
const EditBatch = Loadable(lazy(() => import("pages/Batch-Processing/Recipe/EditBatch")));
const AddOperation = Loadable(lazy(() => import("pages/Batch-Processing/Recipe/AddOperation")));


//######## BKT ########

const BKT = Loadable(lazy(() => import("pages/BKT/Upload_file")));

const ViewDetails = Loadable(lazy(() => import("pages/BKT/DetailsConfig")));

const Dash = Loadable(lazy(() => import("pages/BKT/Dashboard")));

// const Name = Loadable(lazy(() => import('path')));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// render - sample page

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  return {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <DashboardDefault />,
      },
      {
        path: "/dashboard",
        element: <DashboardDefault />,
      },
      {
        path: "/user-profile",
        element: <UserProfile />,
      },
      {
        path: "/configuration",
        element: <Configuration />,
      },
      {
        path: "/addRecipe",
        element: <AddRecipe />,
      },
      {
        path: "/viewRecipe",
        element: <RecipeConfig />,
      },
      {
        path: "/addNRecipe",
        element: <AddNRecipe />,
      },
      {
        path: "/scheduling",
        element: <Scheduling />,
      },
      {
        path: "/Result",
        element: <Report />,
      },
      {
        path: "/Report",
        element: <ReportSkeleton />,
      },
      {
        path: "/batches",
        element: <ScheduledBatches />,
      },
      {
        path: "/userConfig",
        element: <UserConfig2 />,
      },
      {
        path: "/addRecipeForm",
        element: <AddRecipeForm />,
      },
      {
        path: "/accessControl",
        element: <AccessControl />,
      },
      {
        path: "/batch",
        element: <Batch />,
      },
      {
        path: "/sample-testing",
        element: <SampleTesting />,
      },
      {
        path: "/ingredients",
        element: <AddIngredient />,
      },
      {
        path: "/parameters",
        element: <AddData />,
      },
      {
        path: "/product",
        element: <Product />,
      },
      {
        path: "/sampling",
        element: <AddSample />,
      },
      {
        path: "/notifications",
        element: <NotificationPage />,
      },
      {
        path: "/calender",
        element: <Calender />,
      },
      {
        path: "/processesApproval",
        element: <AllProcessesByRecipeId />,
      },
      {
        path: "/recipeMetadata",
        element: <RecipeMetaData />,
      },
      {
        path: "/approveProcess",
        element: <ApproveProcess />,
      },
      {
        path: "/batch-approval",
        element: <Approvals />,
      },
      {
        path: "/edit-recipe",
        element: <EditRecipe />,
      },
      {
        path: "/audit-report",
        element: <Audit />,
      },
      {
        path: "/bom",
        element: <BOM />,
      },
      {
        path: "/bal-material",
        element: <BalanceMaterial />,
      },
      ///######## BKT #############
      {
        path: "/upload-file",
        element: <BKT />,
      },
      {
        path: "/viewDetails",
        element: <ViewDetails />,
      },
      {
        path: "/dash",
        element: <Dash />,
      },
      {
        path: "/generate-qr",
        element: <GenerateQRs />,
      },
      {
        path: "/shift-details",
        element: <Shift />,
      },
      {
        path: "/messages",
        element: <ScheduleNotificationPage />,
      },
      {
        path: "/dag-flow",
        element: <DAGFlowCopy />,
      },
      {
        path: "/edit-batch",
        element: <EditBatch />,
      },
      {
        path: "/add-operation",
        element: <AddOperation />,
      },
    ],
  };
};

export default MainRoutes;
