import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import PlayCircleFilledOutlinedIcon from "@mui/icons-material/PlayCircleFilledOutlined";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Typography as Typography1 } from "antd";
import {
  Typography,
  Grid,
  Box,
  Tab,
  Stack,
  Chip,
  MenuItem,
  OutlinedInput,
  FormControl,
  IconButton,
  TextField,
  InputLabel,
  Tooltip,
  FormHelperText,
  Button,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { Steps, Timeline, Select } from "antd";
import { Table, Modal } from "antd";
import MainCard from "components/MainCard";
import {
  bp_batch_configuration,
  bp_machine_data,
  bp_product_data,
  get_api_function,
  get_recipe_by_product_id,
} from "../../../utils/api";

function Scheduling({ refresh, set_refresh, selectedButton }) {
  const [recipe_data, set_recipe_data] = useState([]);
  const [machine_data, set_machine_data] = useState([]);
  const [recipe_id, set_recipe_id] = useState("");
  const [product, set_product_id] = useState("");
  const [machine_id, set_machine_id] = useState();
  const [batch, set_batch] = useState("");
  const [batch_size, set_batch_size] = useState();
  const [schedule_time, set_schedule_time] = useState("");
  const [selected_recipe, set_selected_recipe] = useState();
  const [product_list, set_product_list] = useState([]);
  const handleChange = (e) => {
    const value = e.target.value;
    const date = new Date(value);
    // Format the date to exclude seconds
    const formattedDate = value.toLocaleString("en-US", {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    // set_schedule_time(
    //   e.target.value.toLocaleString("en-US", {
    //     timeZone: "Asia/Kolkata",
    //   })
    // )

    set_schedule_time(formattedDate);
  };

  //############### Handle API's ##################
  useEffect(() => {
    set_selected_recipe(
      recipe_data?.find((item) => item?.recipe_id === recipe_id)
    );
  }, [recipe_id]);

  const get_recipe = async () => {
    try {
      // bp_get_true_recipe(success_bp_get_recipe_meta_data);
      // bp_machine_data(success_bp_machine_data);
      get_api_function("/get_all_machine_list", success_bp_machine_data);
      bp_product_data(success_bp_product_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_recipe_by_productid = (product) => {
    try {
      get_recipe_by_product_id(success_recipe_by_product, {
        product_id: product,
        ...(selectedButton !== "test" && { recipe_type: true }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const success_bp_get_recipe_meta_data = (res) => {
    set_recipe_data(res?.data?.data);
  };
  const success_bp_machine_data = (res) => {
    if (res.data.status !== "failed") set_machine_data(res?.data?.data);
  };
  const success_recipe_by_product = (res) => {
    if (res?.data?.status === "success") {
      set_recipe_data(res?.data?.data);
    } else {
      set_recipe_data([]);
    }
  };

  const success_bp_product_data = (res) => {
    set_product_list(res?.data?.data);
  };

  const handle_submit = (e) => {
    e.preventDefault();
    submit_function();
  };

  const submit_function = async () => {
    let formattedTime = schedule_time.replace("T", " ").slice(0, 19);
    if (formattedTime.length === 16) {
      // If the time format is without seconds (e.g., "2024-02-19 10:01"), add ":00"
      formattedTime += ":00";
    }
    try {
      await bp_batch_configuration(
        {
          batch_number: batch,
          recipe_id: recipe_id,
          batch_schedule_time: formattedTime,
          batch_size: parseInt(batch_size),
          ...(machine_id ? { machine_id: machine_id } : {}),
        },
        success_bp_batch_configuration
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_batch_configuration = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success("Batch added Successfully");
      reset_function();
      set_refresh(!refresh);
    }
  };
  const reset_function = () => {
    set_recipe_id("");
    set_batch("");
    set_schedule_time("");
    set_product_id("");
    set_batch_size("");
    set_machine_id();
  };
  useEffect(() => {
    get_recipe();
  }, []);

  useEffect(() => {
    reset_function();
  }, [refresh]);

  return (
    <form onSubmit={handle_submit}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Stack spacing={1}>
            <InputLabel htmlFor="product">Product</InputLabel>
            <Select
              showSearch
              id="demo-select-small"
              value={product || null}
              size="large"
              style={{
                width: "100%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              placeholder="Select Product"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children.localeCompare(optionB.children)
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              onChange={(value) => {
                set_product_id(value);
                get_recipe_by_productid(value);
              }}
            >
              {product_list?.map((mdata) => (
                <Select.Option
                  key={mdata.product_data_id}
                  value={mdata.product_data_id}
                >
                  {mdata.product_name}
                </Select.Option>
              ))}
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack spacing={1}>
            <InputLabel htmlFor="recipe">Recipe</InputLabel>
            <Select
              showSearch
              allowClear
              size="large"
              id="demo-select-small"
              placeholder="Select Recipe"
              optionFilterProp="children"
              value={recipe_id || null}
              onChange={(value) => {
                set_recipe_id(value);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              style={{
                width: "100%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
            >
              {recipe_data?.map((mdata) => (
                <Select.Option key={mdata?.recipe_id} value={mdata?.recipe_id}>
                  {mdata.recipe_name}
                </Select.Option>
              ))}
            </Select>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Stack spacing={1}>
            <InputLabel htmlFor="component-outlined">Batch</InputLabel>
            <OutlinedInput
              type="text"
              placeholder={"Enter Batch"}
              required
              fullWidth
              value={batch}
              onChange={(e) => set_batch(e.target.value)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack spacing={1}>
            <InputLabel htmlFor="schedule-time-input">Schedule Time</InputLabel>
            <OutlinedInput
              id="schedule-time-input"
              type="datetime-local"
              inputProps={{
                step: 60,
                min: new Date().toISOString().slice(0, 16),
              }}
              required
              placeholder="Schedule Time"
              value={schedule_time}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack spacing={1}>
            <InputLabel htmlFor="batch-size">Batch Size</InputLabel>
            <OutlinedInput
              type="number"
              placeholder={"Enter Batch Size"}
              required
              fullWidth
              value={batch_size}
              onChange={(e) => set_batch_size(e.target.value)}
              inputProps={{
                min: selected_recipe?.min_quantity,
                max: selected_recipe?.max_quantity,
              }}
            />
            {recipe_id !== "" && (
              <Typography color="secondary">
                Min: {selected_recipe?.min_quantity} Max:{" "}
                {selected_recipe?.max_quantity}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Stack spacing={1}>
            <InputLabel htmlFor="machine">Machine</InputLabel>
            <Select
              labelId="machine"
              id="machine"
              name="machine"
              fullWidth
              placeholder="Select Machine"
              value={machine_id}
              onChange={(value) => set_machine_id(value)}
              style={{
                width: "100%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              variant="borderless"
              size="large"
            >
              {machine_data?.map((machine) => (
                <MenuItem value={machine.machine_id}>{machine.machine_name}</MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <Button type="submit" variant="contained">
              Submit
            </Button>
            <Button
              type="reset"
              variant="contained"
              onClick={() => reset_function()}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export default Scheduling;
